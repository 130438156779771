:root {
    --clr--primary-200: rgba(209, 223, 255, 1);
    --clr--primary-300: rgba(88, 134, 242, 1);
    --clr--primary-400: rgba(56, 104, 217, 1);
    --clr--primary-500: rgba(32, 69, 161, 1);
    --clr--primary-600: rgba(21, 48, 116, 1);

    --clr--grey-200: rgba(245, 245, 245, 1);
    --clr--grey-300: rgba(235, 235, 235, 1);
    --clr--grey-400: rgba(217, 217, 217, 1);
    --clr--grey-500: rgba(191, 191, 191, 1);
    --clr--grey-600: rgba(86, 86, 86, 1);

    --clr-error: rgba(231, 14, 14, 1);
    --clr-success: rgba(24, 195, 52, 1);
}

body {
    color: var(--clr--grey-600);
    font-size: 16px;
    margin: 0;
    font-family: 'Kanit';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

svg {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.title-400 {
    font-weight: 400;
}

.title-500 {
    font-weight: 500 !important;
}

.title-700 {
    font-weight: 700;
}

.text-body {
    font-weight: 300;
}

.text-5 {
    font-size: 1.25rem;
}

.text-7 {
    font-size: 0.875rem;
}

.text-5-half {
    font-size: 1.125rem;
}

.text-6 {
    font-size: 1rem;
}

.text-center {
    text-align: center;
}

.title-2 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.75rem;
}

.title-3 {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.775rem;
}

.subtitle-3 {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.775rem;
}

.subtitle-5 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

.success-message {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 60px;
}

.success-message .ant-message-notice-content {
    color: rgba(0, 161, 65, 1);
    width: min(80%, 500px);
    text-align: left;
    border-radius: 10px;
    background: rgba(192, 255, 217, 1);
    box-shadow: none;
}

.error-message {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 60px;
}

.error-message .ant-message-notice-content {
    color: white;
    width: min(80%, 500px);
    text-align: left;
    border-radius: 10px;
    background: rgba(255, 95, 95, 1);
    box-shadow: none;
}

div.ant-modal-body,
div.ant-form-item>.ant-form-item-label>label,
label.ant-checkbox-wrapper span {
    font-size: 1rem;
}

@media only screen and (max-width: 576px) {
    label.ant-checkbox-wrapper span {
        font-size: 0.875rem;
    }
}

button.ant-modal-close {
    left: 0;
}

input.ant-input,
textarea.ant-input,
span.ant-input-affix-wrapper,
div.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 1rem;
    padding: 2px 11px;
    border-color: var(--clr--primary-200);
    box-shadow: none;
    border-radius: 5px;
    font-weight: 300;
    color: var(--clr--primary-500);
}

label.ant-radio-wrapper {
    color: var(--clr--primary-500);
}

input.ant-input:hover,
textarea.ant-input:hover,
span.ant-input-affix-wrapper:hover,
span.ant-input-affix-wrapper-focused,
span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
div.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
div.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: rgba(32, 69, 161, 1);
    box-shadow: none;
}

input.ant-input:focus,
input.ant-input:active,
textarea.ant-input:focus,
textarea.ant-input:active,
span.ant-input-affix-wrapper:focus,
span.ant-input-affix-wrapper-focused,
span.ant-input-affix-wrapper:active,
div.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
div.ant-select:not(.ant-select-customize-input) .ant-select-selector:active,
div.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--clr--primary-500);
    box-shadow: none;
}

input.ant-input:disabled,
textarea.ant-input:disabled,
span.ant-input-affix-wrapper:disabled,
span.ant-input-affix-wrapper-focused,
span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):disabled,
div.ant-select:not(.ant-select-customize-input) .ant-select-selector:disabled,
div.ant-select:not(.ant-select-disabled):disabled .ant-select-selector {
    border-color: var(--clr--primary-200);
    /* required on iOS */
    opacity: 1;
    -webkit-text-fill-color: var(--clr--primary-500);
    -webkit-opacity: 1;
}


input.ant-input::placeholder,
span.ant-select-selection-placeholder {
    color: var(--clr--primary-200) !important;
}

span.ant-input-password-icon,
span.ant-input-password-icon:hover,
span.ant-input-password-icon:focus {
    color: var(--clr--primary-200);
}


div.ant-modal-body,
div.ant-form-item>.ant-form-item-label {
    padding: 0;
}

div.ant-form-item>.ant-form-item-label>label {
    color: var(--clr--primary-500)
}

div.ant-form-item-label>label.ant-form-item-required::before {
    content: '' !important;
}

div.ant-form-item-label>label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: var(--clr--primary-500);
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

div.ant-form-item-explain.ant-form-item-explain-error>div,
div.ant-form-item-explain.ant-form-item-explain-success>div {
    font-weight: 300;
}

div.ant-modal-content {
    min-width: 300px;
    border-radius: 10px;
}

div.ant-tabs-tab .ant-tabs-tab-btn:hover,
div.ant-tabs-tab .ant-tabs-tab-btn:active {
    color: var(--clr--primary-400)
}

div.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--clr--primary-400);
}

div.ant-tabs-tab {
    font-size: 1rem;
    padding: 6px 6px 10px 6px;
    color: var(--clr--grey-600)
}

div.ant-tabs-ink-bar {
    height: 4px !important;
    position: absolute;
    background: var(--clr--primary-400);
    pointer-events: none;
}

li.ant-pagination-item-active,
li.ant-pagination-item-active:hover,
li.ant-pagination-item-active:focus,
li.ant-pagination-item:hover,
li.ant-pagination-item:focus {
    border-color: var(--clr--primary-400);
}

li.ant-pagination-item-active a,
li.ant-pagination-item-active:hover a,
li.ant-pagination-item-active:focus a,
li.ant-pagination-item:hover a,
li.ant-pagination-item:focus a {
    color: var(--clr--primary-400);
}

li.ant-pagination-next:hover button.ant-pagination-item-link,
li.ant-pagination-next:focus button.ant-pagination-item-link {
    border-color: var(--clr--primary-400);
    color: var(--clr--primary-400);
}

div.ant-dropdown {
    box-shadow: 0px 2px 15px rgba(88, 134, 242, 0.15);
    border-radius: 10px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: white;
}

div.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.1);
}

.ant-input-number {
    width: 100%;
}

.ant-picker {
    width: 100%;
}

.ant-select-item-option-content {
    white-space: normal;
}